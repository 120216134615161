<template>
  <div class="resource" id="Resource">
    <Header/>
    <div class="other__narrow__banner" id="ResourceHumanResources">
      <img :src="'/tw/upload/' + resource.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{resource.title}}</h4>
        </div>
      </div>
    </div>

    <div class="resource_section_one_wrapper">
      <div class="container">
        <div class="other__ck__textblock other__reverse">
          <div class="other__ck__block__left">
            <div class="other__ck__title title__bar">
              <h2>{{resourceHumanResources.title}}</h2>
            </div>
            <p v-html="resourceHumanResources.descript"></p>
          </div>
          <div class="other__ck__block__right">
            <div class="other__ck__img resource__img__box">
              <div class="resource_pic_wrapper">
                <img :src="'/tw/upload/' + resourceHumanResources.image" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="other__ck__textblock">
          <div class="other__ck__block__left">
            <div class="other__ck__img resource__img__box">
              <div class="resource_pic_wrapper">
                <img :src="'/tw/upload/' + resourceHumanResources1.image" alt="" />
              </div>
            </div>
          </div>
          <div class="other__ck__block__right">
            <div class="other__ck__title title__bar">
              <h2>{{resourceHumanResources1.title}}</h2>
            </div>
            <p>
              {{resourceHumanResources1.descript}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="resource_section_two_wrapper" v-html="resourceHumanResources3.content"></div>
    <div class="resource_section_three_wrapper other__ck__block__bg">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2>投遞應徵</h2>
        </div>
        <div class="other__forms">
          <form class="needs-validation" novalidate>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationCustom01">你的姓名</label>
                <input
                v-model="name"
                  type="text"
                  class="form-control"
                  id="validationCustom01"
                  placeholder="請輸入姓名"
                  value="name"
                  required
                />
                <div class="invalid-feedback">
                  請輸入姓名
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="email">E-Mail</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="請輸入E-mail"
                  value="email"
                  required
                />
                <div class="invalid-feedback">
                  請輸入email
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <label for="phone">聯絡電話</label>
                <input
                  v-model="phone"
                  type="text"
                  class="without_validate_input"
                  id="phone"
                  placeholder="請輸入電話"
                  value="phone"
                />
                <div class="invalid-feedback">
                  請輸入電話
                </div>
              </div>
              <div class="col-md-6 mb-3 d-flex flex-column">
                <label for="formFile">夾帶附件</label>
                <div class="file_wrapper">
                  <label for="formFile" class="file_upload">選擇檔案
                    <input class="form-control d-none" type="file" id="formFile" @change="fileChangehandler">
                  </label>
                  <div class="file_wrapper_discription">{{fileContent}}</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <label for="validationCustom01">聯絡傳真</label>
                <input
                  v-model="fax"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom01"
                  placeholder="請輸入傳真"
                  value="fax"
                />
                <div class="invalid-feedback">
                  請輸入傳真
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="validationCustom01">訊息內容</label>
              <textarea
                v-model="content"
                class="form-control"
                id="validationTextarea"
                placeholder="訊息內容"
                required
              ></textarea>
              <div class="invalid-feedback">
                請輸入訊息內容
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                  v-model="check"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="invalidCheck2"
                  required
                />
                <label class="form-check-label" for="invalidCheck2" style="color:black;">
                  本人已閱讀並同意本公司
                </label>
                <a class="other__ck__text__green"
                  data-toggle="modal" 
                  data-target="#exampleModal">
                  個人資料蒐集政策
                </a>
                <div class="invalid-feedback">
                  尚未同意個人資料蒐集政策
                </div>
              </div>
            </div>
            <div class="modal fade" id="exampleModal"
                  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">個人資料收集政策</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                <div class="modal-body" v-html="resourceHumanResources.content">
                </div>
              </div>
              </div>
            </div>
            <button class="btn btn_green btn_submit" type="submit" @click.prevent.stop="SubmitData" :disabled ="disable">
              確認送出
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="resource_section_four_wrapper resource__block"
    id="ResourceContactUs"
    >
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2>{{resourceContactUsData.title}}</h2>
        </div>
        <div class="other__ck__textblock my-5">
          <div class="other__ck__block__left">
            <div class="other__ck__img resource__img__box">
              <div class="resource_pic_wrapper">
                <iframe :src="resourceContactUsData.googlemap" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
          <div class="other__ck__block__right">
            <div class="other__ck__title title__bar">
              <h2>{{resourceContactUsData.locationname}}</h2>
            </div>
            <div class="service__list">
              <div class="service__list__icon map__icon">
                <img src="../assets/pic/place__icon.png" alt="" />
              </div>
              <div class="service__list__content">
                <div class="service__list__content__title">
                  <p class="other__ck__text__green">
                    {{resourceContactUsData.companyname}}
                  </p>
                </div>
                <div class="service__list__content__text">
                  <p v-html="resourceContactUsData.areacontent"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="other__ck__textblock other__reverse my-5">
          <div class="other__ck__block__left">
            <div class="other__ck__title title__bar">
              <h2>{{resourceContactUsData.locationname1}}</h2>
            </div>
            <div class="service__list">
              <div class="service__list__icon map__icon">
                <img src="../assets/pic/place__icon.png" alt="" />
              </div>
              <div class="service__list__content">
                <div class="service__list__content__title">
                  <p class="other__ck__text__green">
                    {{resourceContactUsData.companyname1}}
                  </p>
                </div>
                <div class="service__list__content__text">
                  <p v-html="resourceContactUsData.areacontent1"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="other__ck__block__right">
            <div class="other__ck__img resource__img__box">
              <div class="resource_pic_wrapper">
                <iframe :src="resourceContactUsData.googlemap1" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :loadRecaptchaScript="true" sitekey="6Ld_ohoeAAAAAHqE5M_OY3nX2uwAY6ki5LeYvSJv" @error="errorMethod"
      size="invisible"/>
    </template>
  </div>
</template>

<script>
import { apiResource } from "@/api/pages.js";
import { VueRecaptcha } from 'vue-recaptcha';
import { Toast } from "./../utils/helpers"
import formPostAPI from "./../api/formPost"
import Header from './../components/Header'
export default {
  name: "Resource",
  components: {
    Header,
    VueRecaptcha
  },
  data(){
    return{
      hash: '',
      item:{},
      resource:{},
      resourceContactUs:{},
      resourceHumanResources:{},
      resourceHumanResources1:{},
      resourceHumanResources2:{},
      resourceHumanResources3:{},
      resourceHumanResources4:{},
      resourceHumanVacancies:{},
      resourceContactUsData:{},
      name:'',
      email:'',
      phone:'',
      file:'',
      fax:'',
      content:'',
      check:false,
      token:'',
      disable: false,
      fileContent: 'PDF檔, 限制大小:10MB'
    }
  },
    metaInfo: {
    meta:[
      {
        property: 'og:url',
        content: 'https://www.elevator.com.tw/tw#/'
      },
      {
        property: 'og:locale',
        content: 'zh_TW'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:description',
        content: 'Resource相對於小縮圖而言，大縮圖能顯示的文字量是比較少的，所以圖片上的資訊更加重要'
      },
      {
        property: 'og:title',
        content: '大縮圖分享連結Resource',
        vmid: 'og:title'
      },
      {
        property: 'og:image:type',
        content: 'image/png'
      },
      {
        property: 'og:image',
        content: 'https://github.com/dean9703111/open_graph_sample/blob/master/img/big_thumbnail.png?raw=true'
      },
      {
        property: 'og:image:width',
        content: '1400'
      },
      {
        property: 'og:image:height',
        content: '770'
      },
    ]
  },
  created() {
    this.getData()
    this.getHashValue()
    // console.log(/\d/.replace('1dwrd你'))
    // '123fdsf'.replace()
  },
  mounted() {
    document.querySelector('body').scrollIntoView({behavior: "smooth"})
    setTimeout( () => {
      const target = $(`${this.hash}`).offset().top
      $(window).scrollTop(target)
    }, 100)
  },
  methods: {
    async postDataHandler () {
      const form = document.querySelector('.needs-validation')
      this.disable = true
      try {
        let file = document.querySelector('input[type="file"]')
        const data_JSON = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          data: this.file,
          fax: this.fax,
          content: this.content,
          token: this.token
        }
        const response = await formPostAPI.SubmitResumePost({ data_JSON })
        console.log('response:', response)
        if(response.data.result === 'OK') {
        // if(response.data.reCAPTCHA.success){
          Toast.fire({
            icon: 'success',
            title: '資料已送出！'
          })
          this.name = ''
          this.file = ''
          this.phone = ''
          this.email = ''
          this.fax = ''
          this.content = ''
          this.check = false
          file.value = ''
          this.fileContent = 'PDF檔, 限制大小:10MB'
          form.classList.remove('was-validated')
        }
      }
      catch(e) {
        Toast.fire({
          icon: 'warning',
          title: '資料傳送失敗, 請稍候！'
        })
        console.log('error:',e)
      }
      this.disable = false
    },
    async getData(){
      try {
        const res = await apiResource();
        const {resource,resourceContactUs,resourceHumanResources,
        resourceHumanResources1,resourceHumanResources2,resourceHumanResources3,resourceHumanResources4,resourceHumanVacancies} = res.data;
        this.resource = resource
        this.resourceContactUs = resourceContactUs
        this.resourceHumanResources = resourceHumanResources
        this.resourceHumanResources1 = resourceHumanResources1
        this.resourceHumanResources2 = resourceHumanResources2
        this.resourceHumanResources3 = resourceHumanResources3
        this.resourceHumanResources4 = resourceHumanResources4
        this.resourceHumanVacancies = resourceHumanVacancies
        this.resourceContactUsData = JSON.parse(this.resourceContactUs.json)
      }
      catch {
        console.log('err')
      }
    },
    fileChangehandler (e) {
      console.log(e.target.files)
      let file = document.querySelector('input[type="file"]')
      if(!e.target.files[0]){
        this.fileContent = 'PDF檔, 限制大小:10MB'
        return
      }
      const fileType = e.target.files[0].type.split('/')
      const type = fileType[1]
      const kbyte = Math.ceil(e.target.files[0].size / 1024) 
      if(type !== 'pdf') {
        Toast.fire({
          icon: 'warning',
          title: '請上傳PDF檔',
        })
        file.value = ''
        this.fileContent = 'PDF檔, 限制大小:10MB'
        return
      }
      if(kbyte > 10240) {
        Toast.fire({
          icon: 'warning',
          title: '檔案過大, 請重新上傳'
        })
        file.value = ''
        this.fileContent = 'PDF檔, 限制大小:10MB'
        return
      }
      //建立 file reader 物件
      const reader = new FileReader
      reader.readAsDataURL(e.target.files[0]) 
      reader.onload = () => {
        this.file = reader.result
        console.log(reader.result)
        this.fileContent = e.target.files[0].name
      }
      reader.onerror = (error) => {
        console.log('Error:', error)
      }
    },
    getHashValue(){
      if(this.$route.hash) {
        this.hash = this.$route.hash
      } else {
        this.hash = `#${this.$route.name}`
      }
    },
    async SubmitData() {
      const form = document.querySelector('.needs-validation')
      form.classList.add('was-validated')
      if(!form.checkValidity()) return
      this.$refs.recaptcha.execute();
    },
    onVerify: function (response) {
      this.token = response
      this.postDataHandler()
    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    errorMethod: function (){
      console.log('觸發 vue-recaptcha errorMethod')
    },
    keyNumberHandler () {
      console.log(this.phone)
    } 
  },
};
</script>

<style lang="css">
    .modal-dialog{
      max-height: 80%;
      text-align: left;
    }
    .wrapper {
      display: flex;
      /* align-items: center; */
      justify-content: center;
      flex-direction: column;
    }
    .other__ck__block__left{
      flex:1;
    }
    .other__ck__block__right{
      flex:1;
    }
    .form-control {
      height: 43px;
    }
    .custom-file-text{
      content:"",
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
    .file_wrapper{
      display: flex;
      align-items: center;
      height: 43px;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      overflow: hidden;
    }
    .file_upload {
      padding: 9px;
      height: 43px;
      cursor: pointer;
      border-right: 1px solid #ccc;
      background-color: #009A44;
      color: white;
      margin-bottom: 0px !important;
    }
    @media screen and (min-width: 768px){
      .modal-dialog {
        max-width: 70%;
        max-height: 500px;
      }
      .wrapper{
        flex-direction: row;
      }  
    }
    @media screen and (min-width: 1200px){
      .modal-dialog {
        margin-top: 20vh; 
      }  
    }
</style>