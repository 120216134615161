import axios from 'axios'
import Swal from 'sweetalert2'
// https://www.elevator.com.tw
// hongwei-tw.test123.tw
let url = window.location.host
console.log('window.location.host:', url)
if (url == "localhost:8086") url = "www.elevator.com.tw"
const base = 'https://' + url + '/tw/api'
console.log(base)
export const apiHelper = axios.create({
    baseURL: base,
})
export const Toast = Swal.mixin({
    position: 'center',
    showConfirmButton: false,
    showCancelButton: false,
    timer: 2500
})