import { apiHelper } from "../utils/helpers";

export default {
    SubmitContactPost({ data_JSON }){
        return apiHelper.post('/SubmitContact', data_JSON )
    },
    SubmitResumePost({ data_JSON}){
        return apiHelper.post('/SubmitResume', data_JSON )
    },
    SubmitReportPost({ data_JSON }){
        return apiHelper.post('/SubmitReport', data_JSON )
    }
}